import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, message } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Animals = () => {
  const [animals, setAnimals] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchAnimals = async () => {
      try {
        const response = await fetch(`http://localhost:3001/auth/animals/${user._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch animals');
        }

        const data = await response.json();
        setAnimals(data);
      } catch (error) {
        console.error('Error fetching animals:', error);
      }
    };

    fetchAnimals();
  }, [user._id]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost:3001/auth/animals/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete animal');
      }

      // Remove the deleted animal from the state
      setAnimals((prevAnimals) => prevAnimals.filter((animal) => animal._id !== id));
      message.success('Animal deleted successfully');
    } catch (error) {
      console.error('Error deleting animal:', error);
      message.error('Failed to delete animal');
    }
  };

  return (
    <div>
    <h1>My Pets</h1>
    {animals.length === 0 ? (
      <p>No Pets found. Click the button below to add a pet.</p>
    ) : (
      <Row gutter={16}>
        {animals.map((animal) => (
          <Col key={animal._id} span={6}>
            <Card title={<Link to={`/my-pet/${animal._id}`}>{animal.name}</Link>}>
              <p>Type: {animal.animalType}</p>
              <p>Breed: {animal.breed}</p>
              <p>Age: {animal.age}</p>
              {/* Add more details as needed */}
              <Button onClick={() => handleDelete(animal._id)} type="danger" style={{ marginTop: '10px' }}>
                Delete
              </Button>
            </Card>
          </Col>
        ))}
      </Row>
    )}
  </div>
  );
};

export default Animals;
