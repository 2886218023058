import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import './CategoryPage.css'

const CategoryPage = () => {
  const user = useSelector((state) => state.user);
  const userLGA = user.lga;
  const userState = user.state;

  const [categoryProfessionals, setCategoryProfessionals] = useState([]);
  const { category } = useParams();

  useEffect(() => {
    const fetchCategoryProfessionals = async () => {
      try {
        const response = await fetch(`http://localhost:3001/auth/getProfessionalsByCategory/${category}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const sortedProfessionals = sortProfessionalsByLocation(data, userLGA, userState);
        setCategoryProfessionals(sortedProfessionals);
      } catch (error) {
        console.error('Error fetching category professionals:', error);
      }
    };

    fetchCategoryProfessionals();
  }, [category, userLGA, userState]);

  const sortProfessionalsByLocation = (professionals, userLGA, userState) => {
    const professionalsInUserLGA = [];
    const professionalsInUserState = [];
    const otherProfessionals = [];

    professionals.forEach(professional => {
      if (professional.lga === userLGA) {
        professionalsInUserLGA.push(professional);
      } else if (professional.state === userState) {
        professionalsInUserState.push(professional);
      } else {
        otherProfessionals.push(professional);
      }
    });

    return [...professionalsInUserLGA, ...professionalsInUserState, ...otherProfessionals];
  };

  return (
    <div className="theme-default">
    <div className="category-page">
      <h2 className="category-title">Our Pet {category.charAt(0).toUpperCase() + category.slice(1)} Professionals</h2>
      <div className="professional-grid">
        {categoryProfessionals.map(professional => (
          <Link key={professional._id} to={`/professional/${professional._id}`} className="professional-link">
            <ProfessionalCard professional={professional} />
          </Link>
        ))}
      </div>
    </div>

    </div>
  );
};

const ProfessionalCard = ({ professional }) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="professional-card">
      <img src={professional.picturePath} alt={`${professional.firstName} ${professional.lastName}`} className="professional-image" />
      <h3>{`${professional.firstName} ${professional.lastName}`}</h3>
      <p>Profession: {professional.profession}</p>
      <p>Email: {professional.email}</p>
      {professional.lga === user.lga && (
        <p className="location-match">In your LGA</p>
      )}
      {professional.state === user.state && (
        <p className="location-match">In your state</p>
      )}
    </div>
  );
};

export default CategoryPage;