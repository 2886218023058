import React, { useState, useEffect } from "react";
import { useSelector, useDispatch,  } from "react-redux";
import axios from 'axios';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import {
  message,
} from "antd";
import { setStats } from "state";
import _ from "lodash";
import 'react-multi-carousel/lib/styles.css';
import { setUser , setProfessionals} from "state";
import { Paper, Button } from '@mui/material';
import ProfessionalCarousel from "./Carousel";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Layout, Menu, Input, Typography, Card } from "antd";





// import required modules
import { Pagination } from 'swiper/modules';


import { VetProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import { ClinicProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import { TrainerProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import { AnimalProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import { SitterProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import { WalkerProfessionalCard } from "./ProfessionalCards/ProfessionalCards";
import './MainLogs.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { UserOutlined, EnvironmentOutlined, SearchOutlined } from "@ant-design/icons";

// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

export default function MainLogs() {
  const [viewMode, setViewMode] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null); // Track selected post

  const user = useSelector((state) => state.user);
  const professionals = useSelector((state) => state.professionals);
  const firstName = user.firstName;
  const items =  user.items;
  const { Header, Sider, Content } = Layout;
  const { Title } = Typography;

  const token = useSelector((state) => state.token);
  const userId = useSelector((state) => state.user._id);

  const dispatch = useDispatch();

 
  const [messageApi, contextHolder] = message.useMessage();

  const [categories, setCategories] = useState(['walker','sitter' , 'vet','trainer', 'animal', 'clinics']);

  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Function to toggle mobile menu
    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    // Event listener for mobile menu toggle button
    const hamburgerMenu = document.querySelector("#fs-navigation .fs-toggle");
    if (hamburgerMenu) {
      hamburgerMenu.addEventListener('click', toggleMobileMenu);
    }

    // Event listener for dropdown menu items
    const dropdownItems = document.querySelectorAll('#fs-navigation .fs-li-link');
    dropdownItems.forEach((item) => {
      item.addEventListener('click', toggleDropdown);
    });

    // Cleanup function
    return () => {
      if (hamburgerMenu) {
        hamburgerMenu.removeEventListener('click', toggleMobileMenu);
      }
      dropdownItems.forEach((item) => {
        item.removeEventListener('click', toggleDropdown);
      });
    };
  }, [isMobileMenuOpen]);

  // Function to toggle dropdown menu
  const toggleDropdown = (event) => {
    event.currentTarget.classList.toggle('fs-active');
  };

  const responsive= {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 5,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 3,
      partialVisibilityGutter: 30,
    },
  }
  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await fetch(`http://localhost:3001/auth/getProfessionals?search=${searchQuery}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(setProfessionals(data)); // This will set the user object

      } catch (error) {
        console.error('Error fetching professionals:', error);
      }
    };

    const fetchSuggestions = async (query) => {
      try {
        const response = await fetch(`http://localhost:3001/auth/getSuggestions?query=${query}`);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
console.log(user.isAdmin);

        // Check if the query matches any category
        const categoryMatch = categories.find(category => category.toLowerCase().includes(query.toLowerCase()));

        // Include the category in suggestions if it's a match
        const updatedSuggestions = categoryMatch ? [{ id: categoryMatch, name: categoryMatch }, ...data] : data;

        setSuggestions(updatedSuggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    const debouncedFetchProfessionals = debounce(fetchProfessionals, 300);
    debouncedFetchProfessionals();

    const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);
    debouncedFetchSuggestions(searchQuery);

    return () => {
      debouncedFetchProfessionals.cancel();
      debouncedFetchSuggestions.cancel();
    };
  }, [searchQuery]);

    const walkers = professionals.filter(professional => professional.tags.includes('walker'));
    const animals = professionals.filter(professional => professional.tags.includes('animal'));  
    const trainers = professionals.filter(professional => professional.tags.includes('trainer'));
  const sitters = professionals.filter(professional => professional.tags.includes('sitter'));
  const vets = professionals.filter(professional => professional.tags.includes('vet'));
  const clinics = professionals.filter(professional => professional.tags.includes('clinics'));
  

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Fetch suggestions based on the current query
    fetchSuggestions(query);
  };


  const fetchSuggestions = async (query) => {
    try {
      const response = await fetch(`http://localhost:3001/auth/getSuggestions?query=${query}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setSuggestions(data);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    // Use setTimeout to delay the execution of the blur action
    setTimeout(() => {
      setIsSearchFocused(false);
    }, 500); // 100ms should be enough, but you can adjust the delay based on your needs
  };
  

  const replaceUser = async (user) => {
    const bodyData = {
      id: user._id,
    firstName: user.firstName,
    email: user.email,    
    impressions: user.impressions,
    items: user.items,
    };
  
    try {
      // Display loading message
      messageApi.loading({ content: 'Replacing data...', key: 'replaceUserMessage' });
  
      const response = await fetch("http://localhost:3001/auth/replace", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      });
  
      const data = await response.json();
  
      // Display success message
      messageApi.success({ content: 'Data replaced successfully!', key: 'replaceUserMessage' });
            setTimeout(messageApi.destroy,2000);

  
      console.log(data);
    } catch (error) {
      // Display error message
      messageApi.error({ content: 'Failed to replace data!', key: 'replaceUserMessage' });
            setTimeout(messageApi.destroy,2000);

      console.error("Error replacing user:", error);
    }
  };




  // Filter walker professionals based on user's state and LGA
const filteredWalkerProfessionals = walkers.filter(professional => {
  return professional.state === user.state && professional.lga === user.lga;
});

const filteredWalkerProfessionalsByState = walkers.filter(professional => {
  return professional.state === user.state;
});

const filteredSitterProfessionals = sitters.filter(professional => {
  return professional.state === user.state && professional.lga === user.lga;
});

// Filter sitter professionals based on user's state
const filteredSitterProfessionalsByState = sitters.filter(professional => {
  return professional.state === user.state;
});

// Add filtering for other categories as needed
// Filter vet professionals based on user's state and LGA
const filteredVetProfessionals = vets.filter(professional => {
  return professional.state === user.state && professional.lga === user.lga;
});


// Filter clinic professionals based on user's state and LGA
const filteredClinicProfessionals = clinics.filter(professional => {
  return professional.state === user.state && professional.lga === user.lga;
});

// Filter vet professionals based on user's state
const filteredVetProfessionalsByState = vets.filter(professional => {
  return professional.state === user.state;
});

// Filter clinic professionals based on user's state
const filteredClinicProfessionalsByState = clinics.filter(professional => {
  return professional.state === user.state;
});

// Filter trainer professionals based on user's state
const filteredTrainerProfessionalsByState = trainers.filter(professional => {
  return professional.state === user.state;
});

const filteredAnimalProfessionalsByState = animals;


// Filter trainer professionals based on user's state and LGA
const filteredTrainerProfessionals = trainers.filter(professional => {
  return professional.state === user.state && professional.lga === user.lga;
});


const categoryToHtmlMapping = {
  'vet': 'Verified Vetinerians',
  'clinics': 'Vetinary Clinics',
  'sitter':'Pet Sitters',
  'trainer':'Pet Trainers',
  'walker':'Pet Walkers',
  'animal':'Adopt a pet',
};

const categoryToIconMapping = {
  'vet': { icon: 'fa-user-md', title: 'Verified Veterinarians' },
  'clinics': { icon: 'fa-hospital', title: 'Veterinary Clinics' },
  'sitter': { icon: 'fa-baby-carriage', title: 'Pet Sitters' },
  'trainer': { icon: 'fa-dumbbell', title: 'Pet Trainers' },
  'walker': { icon: 'fa-walking', title: 'Pet Walkers' },
  'animal': { icon: 'fa-paw', title: 'Adopt a Pet' },
};


return (
  <Layout style={{ minHeight: "100vh" }}>
    <Sider theme="light" width={240}>
      <Menu mode="inline" style={{ height: "100%" }}>
        <Menu.Item key="profile" icon={<UserOutlined />}>
          <Link to="/profile">My Profile</Link>
        </Menu.Item>
        <Menu.Item key="locations" icon={<EnvironmentOutlined />}>
          <Link to="/filter-by-locations">Filter By Locations</Link>
        </Menu.Item>
        {categories.map((category) => (
          <Menu.Item key={category} icon={<i className={`fas ${categoryToIconMapping[category]?.icon}`} />}>
            <Link to={`/category/${category}`}>{category}</Link>
          </Menu.Item>
        ))}
        <Menu.Item key="search">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            onFocus={handleSearchFocus}
            onBlur={handleSearchBlur}
          />
        </Menu.Item>
      </Menu>
    </Sider>

    {/* Main Layout */}
    <Layout>
      <Header
        style={{
          background: "#fff",
          padding: "0 24px",
          borderBottom: "1px solid #f0f0f0",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={4}>Pet Services</Title>
        {user.isAdmin && (
          <Link to="/dashboard" className="ant-btn ant-btn-primary">
            Dashboard
          </Link>
        )}
      </Header>

      <Content style={{ margin: "24px", padding: "24px", background: "#fff" }}>
        <Title level={3} style={{ marginBottom: "16px" }}>
          Our Pet Walkers in {user.lga}
        </Title>
        <ProfessionalCarousel
          professionals={filteredWalkerProfessionals}
          CardComponent={WalkerProfessionalCard}
        />

        <Title level={3} style={{ marginTop: "32px" }}>
          Our Pet Walkers in {user.state}
        </Title>
        <ProfessionalCarousel
          professionals={filteredWalkerProfessionalsByState}
          CardComponent={WalkerProfessionalCard}
        />

        <Title level={3} style={{ marginTop: "32px" }}>
          Our Pet Sitters in {user.lga}
        </Title>
        <ProfessionalCarousel
          professionals={filteredSitterProfessionals}
          CardComponent={SitterProfessionalCard}
        />

        <Title level={3} style={{ marginTop: "32px" }}>
          Here Are Some Pets You Can Adopt
        </Title>
        <ProfessionalCarousel
          professionals={filteredAnimalProfessionalsByState}
          CardComponent={AnimalProfessionalCard}
        />
      </Content>
    </Layout>
  </Layout>
);
}
 
