import { BrowserRouter, Navigate, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfessionalDetails from "components/widgets/ProfessionalDetails";
import ProfilePage from "scenes/homePage/ProfilePage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import CategoryPage from "components/widgets/CategoryPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CreateAnimal from "scenes/homePage/CreateAnimal";
import AnimalDetails from "scenes/animalDetails/AnimalDetails";
import Dashboard from "scenes/admin/Dashboard";
import ProfessionalsFilter from "components/widgets/LocationPage";
import AboutPage from "scenes/homePage/AboutPage";
import AboutUsPage from "scenes/homePage/AboutUsPage";
import Footer from "scenes/homePage/Footer";
import NavBar from "scenes/homePage/NavBar";
import ServicesPage from "scenes/homePage/ServicesPage";
import Faq from "scenes/homePage/Faq";
import Article1 from "./scenes/homePage/articles/Article1";
import Article2 from "scenes/homePage/articles/Article2";
import Article3 from "scenes/homePage/articles/Article3";
import Article4 from "scenes/homePage/articles/Article4";
import Donate from "scenes/homePage/Donate";
import TermsAndConditions from "components/legal/TermsAndConditions";
import PrivacyPolicy from "components/legal/PrivacyPolicy";
import BlogMenu from "scenes/Blog.js/BlogMenu";
import CatRescueArticle from "scenes/Blog.js/catRescue/CatRescue";
import BlackieRescueArticle from "scenes/Blog.js/blackie-dog/BlackieDog";
import HorseRescueArticle from "scenes/Blog.js/horseRescue/HorseRescue";
import HorseOwnershipTipsArticle from "scenes/Blog.js/horseOwnershipTips/HorseOwnershipTipsArticle";
import IndigenousDogPrograms from "scenes/initiativesPages/IndegenousDogProgramsPage";
import SeniorPrograms from "scenes/initiativesPages/SeniorProgramsPage";
import SpayNeuterPrograms from "scenes/initiativesPages/SpayAndNeuterPage";
import StreetDogProgram from "scenes/initiativesPages/StreetDogProgramPage";
import VaccinationProgram from "scenes/initiativesPages/VaccinationProgram";
import { useState } from "react";
import Article5 from "scenes/homePage/articles/Article5";
import Article6 from "scenes/homePage/Article6";


function App() {

  const location = useLocation();
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const [showLoginForm, setShowLoginForm] = useState(false);
  const noNavBarRoutes = ["/home", "/dashboard", "/profile"];
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="app" style={{ overflowX: "hidden" }}>
        <ThemeProvider theme={theme}>
            {!noNavBarRoutes.includes(location.pathname) && (
              <NavBar showLoginForm={showLoginForm} setShowLoginForm={setShowLoginForm} />
            )}
            <CssBaseline />
            <Routes>
              <Route path="/" element={<LoginPage showLoginForm={showLoginForm} setShowLoginForm={setShowLoginForm} />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/https://paystack.com/pay/s96g0g5t6b" element={<Donate />} />
              <Route path="/faq" element={<Faq />} />
              <Route
                path="/Things You Should Know About Animal Rescuing"
                element={<Article1 />}
              />
               <Route
                path="/Pinnock Estate Alleged Dog Mauling Incident"
                element={<Article5 />}
              />
                 <Route
                path="/Meet Simba - Looking for a Forever Home!"
                element={<Article6 />}
              />
              <Route
                path="/Promoting Responsible Animal Care and Breeding: What You Need to Know"
                element={<Article2 />}
              />
              <Route
                path="/Why You Should Adopt, Not Shop: The Benefits of Pet Adoption"
                element={<Article3 />}
              />
              <Route path="/article4" element={<Article4 />} />

              <Route
                path="/filter-by-locations"
                element={<ProfessionalsFilter />}
              />
              <Route path="/add-pet" element={<CreateAnimal />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/my-pet/:id" element={<AnimalDetails />} />
              <Route
                path="/home"
                element={isAuth ? <HomePage /> : <Navigate to="/" />}
              />
              <Route
                path="/professional/:id"
                element={<ProfessionalDetails />}
              />
              <Route path="/category/:category" element={<CategoryPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />

              <Route path="/blog" element={<BlogMenu />} />
              <Route path="/blog/cat-rescue" element={<CatRescueArticle />} />
              <Route path="/blog/meet-blackie" element={<BlackieRescueArticle />} />
              <Route path="/blog/horse-ownership-tips" element={<HorseOwnershipTipsArticle />} />
              <Route
                path="/blog/welcome-rafah-horse-tips"
                element={<HorseRescueArticle />}
              />
              <Route
                path="/initiatives/indigenous-dog-programs"
                element={<IndigenousDogPrograms />}
              />
              <Route
                path="/initiatives/senior-programs"
                element={<SeniorPrograms />}
              />
              <Route
                path="/initiatives/spay-neuter-programs"
                element={<SpayNeuterPrograms />}
              />
              <Route
                path="/initiatives/street-dog-program"
                element={<StreetDogProgram />}
              />
              <Route
                path="/initiatives/vaccination-program"
                element={<VaccinationProgram />}
              />
            </Routes>
            <Footer />
          </ThemeProvider>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
