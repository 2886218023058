import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Input, Button, message } from 'antd';
import './CreateAnimal.css'
const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  description: Yup.string().required('Description is required'),
  animalType: Yup.string().required('Animal Type is required'),
  breed: Yup.string().required('Breed is required'),
  age: Yup.number().required('Age is required').positive('Age must be a positive number'),
  medicalRequirements: Yup.string().required('Medical Requirements is required'),
  foodRequirements: Yup.string().required('Food Requirements is required'),
  supplies: Yup.string().required('Supplies is required'),
});

const CreateAnimal = () => {
  const user = useSelector((state) => state.user);

  const initialValues = {
    name: '',
    description: '',
    animalType: '',
    breed: '',
    age: '',
    medicalRequirements: '',
    foodRequirements: '',
    supplies: '',
  };

  const handleSubmit = async (values, actions) => {
    try {
      const response = await fetch('http://localhost:3001/auth/createPost', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ ...values, id: user._id, firstName: user.firstName }),
      });

      if (!response.ok) {
        throw new Error('Failed to create post');
      }

      message.success('Pet added successfully');
    } catch (error) {
      console.error('Error creating post:', error);
      message.error('Failed to create post');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const animalTypes = ['Dog', 'Cat', 'Bird', 'Rabbit', 'Fish', 'Reptile', 'Other'];


  return (
    <div className="create-animal-form">
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
  {({ isSubmitting }) => (
    <Form>
      <div className="form-group">
        <label htmlFor="name">Name<span className="required-asterisk">*</span></label>
        <Field as={Input} id="name" name="name" />
        <ErrorMessage name="name" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description<span className="required-asterisk">*</span></label>
        <Field as={Input.TextArea} id="description" name="description" />
        <ErrorMessage name="description" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="animalType">Animal Type<span className="required-asterisk">*</span></label>
        <Field as="select" id="animalType" name="animalType" className="ant-input">
          <option value="">Select Animal Type</option>
          {animalTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </Field>
        <ErrorMessage name="animalType" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="breed">Breed<span className="required-asterisk">*</span></label>
        <Field as={Input} id="breed" name="breed" />
        <ErrorMessage name="breed" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="age">Age<span className="required-asterisk">*</span></label>
        <Field as={Input} type="number" id="age" name="age" />
        <ErrorMessage name="age" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="medicalRequirements">Medical Requirements<span className="required-asterisk">*</span></label>
        <Field as={Input} id="medicalRequirements" name="medicalRequirements" />
        <ErrorMessage name="medicalRequirements" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="foodRequirements">Food Requirements<span className="required-asterisk">*</span></label>
        <Field as={Input} id="foodRequirements" name="foodRequirements" />
        <ErrorMessage name="foodRequirements" component="div" className="error" />
      </div>
      <div className="form-group">
        <label htmlFor="supplies">Supplies<span className="required-asterisk">*</span></label>
        <Field as={Input} id="supplies" name="supplies" />
        <ErrorMessage name="supplies" component="div" className="error" />
      </div>
      <Button type="primary" htmlType="submit" loading={isSubmitting}>
        Add Pet
      </Button>
    </Form>
  )}
</Formik>
    </div>
  );
};

export default CreateAnimal;
