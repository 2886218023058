// ProfilePage.jsx
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './ProfilePage.css'

import {
    message,
  } from "antd";
  import { useDropzone } from 'react-dropzone';
  import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
  import { v4 } from 'uuid';
  import { storage } from '../../firebase';
import Animals from './Animals';

const ProfilePage = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [showEditProfile, setShowEditProfile] = useState(false);

  const user = useSelector((state) => state.user);
  const id = useSelector((state) => state.user._id);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [showUpload, setShowUpload] = useState(false);

  const toggleUpload = () => {
    setShowUpload(!showUpload);
  };

  const replaceUser = async () => {
    const bodyData = {
      id: user._id,
      firstName: editedUser.firstName,
      phoneNumber: editedUser.phoneNumber,
      state: editedUser.state,
      lga: editedUser.lga
    };

    try {
      // Display loading message
      messageApi.loading({ content: 'Replacing data...', key: 'replaceUserMessage' });

      const response = await fetch("http://localhost:3001/auth/replace", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      // Display success message
      messageApi.success({ content: 'Data replaced successfully!', key: 'replaceUserMessage' });
      setTimeout(messageApi.destroy, 2000);

      console.log(data);
    } catch (error) {
      // Display error message
      messageApi.error({ content: 'Failed to replace data!', key: 'replaceUserMessage' });
      setTimeout(messageApi.destroy, 2000);

      console.error("Error replacing user:", error);
    }
  };

  const [imagesUpload, setImagesUpload] = useState([null, null, null]);
  const [uploadedImageURLs, setUploadedImageURLs] = useState([]);
  
  const handleDrop = (acceptedFiles, index) => {
    // Update state with the accepted file at the specified index
    const updatedImages = [...imagesUpload];
    updatedImages[index] = acceptedFiles[0];
    setImagesUpload(updatedImages);
  };

  const updatePicturePaths = async (userId, imageUrl, index) => {
    try {
      const response = await fetch(`http://localhost:3001/auth/users/updatePicturePaths/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageUrl, index })
      });
  
      if (!response.ok) {
        throw new Error('Failed to update picture path');
      }
  
      return true;
    } catch (error) {
      console.error('Error updating picture path:', error);
      return false;
    }
  };
  

  const handleUpload = async (index) => {
    const image = imagesUpload[index];
    if (!image) return;
  
    const imageRef = ref(storage, `userImages/${image.name + v4()}`);
    try {
      await uploadBytes(imageRef, image);
      const downloadURL = await getDownloadURL(imageRef);
      
      // Update the state with the uploaded image URL at the specified index
      setUploadedImageURLs(prevUrls => {
        const updatedUrls = [...prevUrls];
        updatedUrls[index] = downloadURL;
        return updatedUrls;
      });
  
      // Update picture paths for the professional after successful upload
      updatePicturePathsForProfessional(id, downloadURL, index);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  // Call this function after successful image uploads to update the picture paths
const updatePicturePathsForProfessional = async (userId, imageUrl, index) => {
  try {
    const success = await updatePicturePaths(userId, imageUrl, index);
    if (success) {
      console.log(`Picture path ${index + 1} updated successfully`);
      // Refresh the professional data after successful update
    } else {
      console.log('Failed to update picture path');
    }
  } catch (error) {
    console.error('Error updating picture path:', error);
  }
};

  // First dropzone
  const { getRootProps: getRootProps1, getInputProps: getInputProps1, isDragActive: isDragActive1 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 0),
  });

  // Second dropzone
  const { getRootProps: getRootProps2, getInputProps: getInputProps2, isDragActive: isDragActive2 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 1),
  });

  // Third dropzone
  const { getRootProps: getRootProps3, getInputProps: getInputProps3, isDragActive: isDragActive3 } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 2),
  });

  const states = ['Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'FCT', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara']; // Replace with Nigerian states

  const lgasByState = {
    Abia: ['Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Ikwuano', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi'], // Replace with LGAs for Abia state
    Lagos: ['Agege', 'Ajeromi-Ifelodun', 'Alimosho', 'Amuwo-Odofin', 'Apapa', 'Badagry', 'Epe', 'Eti-Osa', 'Ibeju-Lekki', 'Ifako-Ijaiye', 'Ikeja', 'Ikorodu', 'Kosofe', 'Lagos Island', 'Lagos Mainland', 'Mushin', 'Ojo', 'Oshodi-Isolo', 'Shomolu', 'Surulere'] // Replace with LGAs for Lagos state
    // Add other Nigerian states and their corresponding LGAs
  };

  const [editedUser, setEditedUser] = useState({
    firstName: user.firstName,
    phoneNumber: user.phoneNumber,
    state: user.state,
    lga: user.lga
  });
  console.log(editedUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    // Update the LGAs based on the selected state
    const updatedLgas = lgasByState[selectedState];
    setEditedUser({ ...editedUser, state: selectedState, lga: updatedLgas[0] });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the replaceUser function with the edited user data
      await replaceUser(editedUser);
      setShowEditProfile(false);

    } catch (error) {
      console.error('Error replacing user:', error);
    }
  };

console.log(user);
  return (
    <div className='profile-container'>  
    <div className="profile-page">
    {user ? (
      <div className="user-details">
      {user.picturePath && (
          <div className="profile-picture-container" onClick={toggleUpload}>
          <img
            src={user.picturePath}
            alt="User Profile"
            style={{
              maxWidth: '100%',
              maxHeight: '200px',
              objectFit: 'cover', // Ensures the image covers the container
            }}
          />
            {/* Profile picture upload section */}
{showUpload && (
  <div className="profile-picture-upload">
    {/* First dropzone */}
    <div {...getRootProps1()} style={getDropzoneStyle(isDragActive1)}>
      <input className='input'  {...getInputProps1()} />
      {imagesUpload[0] ? (
        <p className='p' >Selected File: {imagesUpload[0].name}</p>
      ) : (
        <p className='p' >{isDragActive1 ? 'Drop the image file here...' : 'Drag \'n\' drop an image file here, or click to select one'}</p>
      )}
    </div>
    <button className='button' onClick={() => handleUpload(0)}>Upload Image 1</button>
  </div>
)}

        </div>
        )}
        <div>
      <p className='p' >Email: {user.email}</p>
      <p className='p' >Name: {user.firstName}</p>
      <p className='p' >Phone Number: {user.phoneNumber}</p>
      <p className='p' >Local Government Area: {user.lga}</p>
      <p className='p' >State: {user.state}</p>
    </div>
    {showEditProfile ? (
        <form className='form' onSubmit={handleFormSubmit}>
          <input className='input' 
            type="text"
            name="firstName"
            value={editedUser.firstName}
            onChange={handleChange}
            placeholder="First Name"
          />
          <br />
          <input className='input' 
            type="tel"
            name="phoneNumber"
            value={editedUser.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
          />
          <br />
          <select className='select' name="state" value={editedUser.state} onChange={handleStateChange}>
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
          <br />
          <select className='select' name="lga" value={editedUser.lga} onChange={handleChange}>
            <option value="">Select LGA</option>
            {editedUser.state && lgasByState[editedUser.state]?.map((lga) => (
              <option key={lga} value={lga}>{lga}</option>
            ))}
          </select>
          <br />
          <button className='button' type="submit">Save Changes</button>
        </form>
      ) : (
        <button className='button' onClick={() => setShowEditProfile(true)}>Edit Profile</button>
      )}


        <Animals />
        <Link className='add-a-pet' to="/add-pet">Add a pet</Link>
      </div>
    ) : (
      <p className='p' >No user logged in</p>
    )}
  </div>
</div>
  );
};

export default ProfilePage;

const getDropzoneStyle = (isDragActive) => ({
  border: `2px dashed ${isDragActive ? 'green' : '#cccccc'}`,
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
});
