import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './StatsCard.css';

// Component for fetching and displaying Professionals
const ProfessionalsComponent = () => {
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await fetch('http://localhost:3001/auth/professionals'); // Adjust the auth endpoint according to your backend route
        if (!response.ok) {
          throw new Error('Failed to fetch professionals');
        }
        const data = await response.json();
        setProfessionals(data);
      } catch (error) {
        console.error('Error fetching professionals:', error);
      }
    };

    fetchProfessionals();
  }, []);

  return (
    <div>
      <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>Professionals</h2>
      <Grid container spacing={2}>
        {professionals.map((professional) => (
          <Grid item key={professional._id} xs={12} sm={6} md={4} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {professional.firstName} {professional.lastName}
                </Typography>
                <Typography variant="body1">Profession: {professional.profession}</Typography>
                <Typography variant="body1">Address: {professional.address}</Typography>
                <Typography variant="body1">Email: {professional.email}</Typography>
                <Typography variant="body1">LGA: {professional.lga}</Typography>
                <Typography variant="body1">State: {professional.state}</Typography>
                {/* Add more fields as needed */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

// Component for fetching and displaying Posts
const PostsComponent = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('http://localhost:3001/auth/posts'); // Adjust the auth endpoint according to your backend route
        if (!response.ok) {
          throw new Error('Failed to fetch posts');
        }
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div>
    <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>Pets</h2>
    <Grid container spacing={2}>
      {posts.map((post) => (
        <Grid item key={post._id} xs={12} sm={6} md={4} lg={3}>
        <Link to={`/my-pet/${post._id}`}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                User ID: {post.userId}
              </Typography>
              <Typography variant="body1">Name: {post.name}</Typography>
              <Typography variant="body1">Tags: {post.tags.join(', ')}</Typography>
              <Typography variant="body1">Animal Type: {post.animalType}</Typography>
              <Typography variant="body1">Breed: {post.breed}</Typography>
              <Typography variant="body1">Age: {post.age}</Typography>
              <Typography variant="body1">Medical Requirements: {post.medicalRequirements}</Typography>
              {/* Add more fields as needed */}
            </CardContent>
          </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  </div>
  
  );
};

// Component for fetching and displaying Users
const UsersComponent = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('http://localhost:3001/auth/users'); // Adjust the auth endpoint according to your backend route
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
    <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>Users</h2>
    <Grid container spacing={2}>
      {users.map((user) => (
        <Grid item key={user._id} xs={12} sm={6} md={4} lg={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                First Name: {user.firstName}
              </Typography>
              <Typography variant="body1">Email: {user.email}</Typography>
              <Typography variant="body1">LGA: {user.lga}</Typography>
              <Typography variant="body1">State: {user.state}</Typography>
              <Typography variant="body1">Country: {user.country}</Typography>
              <Typography variant="body1">Verified: {user.verified ? "Yes" : "No"}</Typography>
              <Typography variant="body1">Is Admin: {user.isAdmin ? "Yes" : "No"}</Typography>
              {/* Add more fields as needed */}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </div>

  );
};



const StatsCard = () => {
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [professionals, setProfessionals] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('http://localhost:3001/auth/posts');
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('http://localhost:3001/auth/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchProfessionals = async () => {
      try {
        const response = await axios.get('http://localhost:3001/auth/professionals');
        setProfessionals(response.data);
      } catch (error) {
        console.error('Error fetching professionals:', error);
      }
    };

    fetchPosts();
    fetchUsers();
    fetchProfessionals();
  }, []);

  const numPosts = posts.length;
  const numUsers = users.length;
  const numProfessionals = professionals.length;

  return (
    <div className="stats-card-container">
      <div className="stats-card">
        <h2 className="stats-card-heading">Statistics</h2>
        <div className="stats-card-content">
          <div className="stats-card-item">
            <h3 className="stats-card-item-heading">Number of Animals</h3>
            <p className="stats-card-item-value">{numPosts}</p>
          </div>
          <div className="stats-card-item">
            <h3 className="stats-card-item-heading">Number of Users</h3>
            <p className="stats-card-item-value">{numUsers}</p>
          </div>
          <div className="stats-card-item">
            <h3 className="stats-card-item-heading">Number of Professionals</h3>
            <p className="stats-card-item-value">{numProfessionals}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;

export { ProfessionalsComponent, PostsComponent, UsersComponent, StatsCard };
