import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import './LocationPage.css'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'  // Add this import


const ProfessionalsFilter = () => {
  const [professionals, setProfessionals] = useState([]);
  const [selectedState, setSelectedState] = useState('Lagos');
  const [selectedLga, setSelectedLga] = useState('Agege');
  const [tags, setTags] = useState(['walker','trainer', 'clinics','animal', 'vet', 'sitter']);
  const [selectedTag, setSelectedTag] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [states, setStates] = useState([
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River',
    'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'FCT', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano',
    'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo',
    'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
  ]);
  const [lgas, setLgas] = useState({
    'Abia': ['Aba North', 'Aba South', 'Arochukwu', 'Bende', 'Ikwuano', 'Isiala Ngwa North', 'Isiala Ngwa South', 'Isuikwuato', 'Obi Ngwa', 'Ohafia', 'Osisioma', 'Ugwunagbo', 'Ukwa East', 'Ukwa West', 'Umuahia North', 'Umuahia South', 'Umu Nneochi'],
    'Adamawa': ['Demsa', 'Fufore', 'Ganye', 'Girei', 'Gombi', 'Guyuk', 'Hong', 'Jada', 'Lamurde', 'Madagali', 'Maiha', 'Mayo Belwa', 'Michika', 'Mubi North', 'Mubi South', 'Numan', 'Shelleng', 'Song', 'Toungo', 'Yola North', 'Yola South'],
    'Akwa Ibom': ['Abak', 'Eastern Obolo', 'Eket', 'Esit Eket', 'Essien Udim', 'Etim Ekpo', 'Etinan', 'Ibeno', 'Ibesikpo Asutan', 'Ibiono-Ibom', 'Ika', 'Ikono', 'Ikot Abasi', 'Ikot Ekpene', 'Ini', 'Itu', 'Mbo', 'Mkpat-Enin', 'Nsit-Atai', 'Nsit-Ibom', 'Nsit-Ubium', 'Obot Akara', 'Okobo', 'Onna', 'Oron', 'Oruk Anam', 'Udung-Uko', 'Ukanafun', 'Uruan', 'Urue-Offong/Oruko', 'Uyo'],
    'Lagos': ['Agege', 'Ajeromi-Ifelodun', 'Alimosho', 'Amuwo-Odofin', 'Apapa', 'Badagry', 'Epe', 'Eti-Osa', 'Ibeju-Lekki', 'Ifako-Ijaiye', 'Ikeja', 'Ikorodu', 'Kosofe', 'Lagos Island', 'Lagos Mainland', 'Mushin', 'Ojo', 'Oshodi-Isolo', 'Shomolu', 'Surulere']
    // Add more states and LGAs here
  });

  // Update LGAs when state changes
  useEffect(() => {
    setSelectedLga(''); // Reset selected LGA when state changes
  }, [selectedState]);

  // Fetch professionals based on search query, state, LGA, and tag
  const fetchProfessionals = async () => {
    try {
      const url = new URL('http://localhost:3001/auth/getFilteredProfessionals');
      url.searchParams.append('state', selectedState);
      url.searchParams.append('lga', selectedLga);
      url.searchParams.append('tag', selectedTag);
  
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      setProfessionals(data);
    } catch (error) {
      console.error('Error fetching professionals:', error);
    }
  };
  

  const handleSearch = async () => {
    await fetchProfessionals();
  };

  return (
    <div className='location-filter'>
            <h2 id="filterTitle">Filter by Location</h2>
      <Tooltip anchorId="filterTitle" content="Search for the service of your choice in your desired location." />



      <label htmlFor="stateSelect">State:</label>
      <select  className='location-select' id="stateSelect" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
        <option value="">Select State</option>
        {states.map((state) => (
          <option key={state} value={state}>{state}</option>
        ))}
      </select>

      <label htmlFor="lgaSelect">LGA:</label>
      <select  className='location-select' id="lgaSelect" value={selectedLga} onChange={(e) => setSelectedLga(e.target.value)} disabled={!selectedState}>
        <option value="">Select LGA</option>
        {selectedState && lgas[selectedState].map((lga) => (
          <option key={lga} value={lga}>{lga}</option>
        ))}
      </select>

      <label htmlFor="tagSelect">Tag:</label>
      <select className='location-select'  id="tagSelect" value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
        <option value="">All</option>
        {tags.map((tag) => (
          <option key={tag} value={tag}>{tag}</option>
        ))}
      </select>
      <button  className='search-location-button' onClick={handleSearch}>Search</button>

      {/* Display professionals list */}
      <div>
        {professionals.length === 0 ? (
          <Typography variant="body1" className="no-matches">No matches found.</Typography>
        ) : (
          professionals.map((professional) => (
            <Link key={professional._id} to={`/professional/${professional._id}`} style={{ textDecoration: 'none' }}>
              <Card key={professional._id} sx={{ minWidth: 275, margin: '10px 0', backgroundColor: '#f5f5f5' }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Name: {professional.firstName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    State: {professional.state}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    LGA: {professional.lga}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          ))
        )}
      </div>
    </div>
  );

};

export default ProfessionalsFilter;
